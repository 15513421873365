var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header"},[_c('div',{staticClass:"menu",class:{
        'show-menu': _vm.mobileMenu,
        'home-pc-menu': _vm.$route.name === 'Home',
      }},[_c('div',[_c('router-link',{staticClass:"hidden-menu",class:{ 'router-active': ['Home'].includes(_vm.$route.name) },attrs:{"to":"/"}},[_vm._v("首页")]),_c('router-link',{class:{
            'router-active': ['Product', 'ProductDetail'].includes(
              _vm.$route.name
            ),
          },attrs:{"to":"/product"}},[_vm._v("产品中心")]),_c('router-link',{class:{
            'router-active': ['SwordCulture', 'SwordCultureDetail'].includes(
              _vm.$route.name
            ),
          },attrs:{"to":"/swordCulture"}},[_vm._v("剑文化")]),_c('router-link',{class:{ 'router-active': _vm.$route.name === 'About' },attrs:{"to":{ name: 'About', params: { type: 'qyjj' } }}},[_vm._v("关于棠溪")]),_c('span',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":"棠溪"}})])],1),_c('router-link',{class:{
            'router-active': [
              'MediaDetail',
              'Media',
              'MediaWccp',
              'MediaSpzx',
              'SpzxDetail',
              'WccpDetail' ].includes(_vm.$route.name),
          },attrs:{"to":"/media"}},[_vm._v("媒体聚焦")]),_c('router-link',{class:{ 'router-active': ['Order'].includes(_vm.$route.name) },attrs:{"to":"/order"}},[_vm._v("订购流程")]),_c('router-link',{class:{ 'router-active': ['Wealth'].includes(_vm.$route.name) },attrs:{"to":"/wealth"}},[_vm._v("财富机会")]),_c('router-link',{class:{
            'router-active': _vm.$route.name === 'Contact',
            'hidden-menu': _vm.$route.name === 'Home',
          },attrs:{"to":"/contact"}},[_vm._v("联系我们")]),(_vm.token)?_c('router-link',{staticClass:"hidden-menu",class:{ 'router-active': _vm.$route.name === 'Collection' },attrs:{"to":"/collection"}},[_vm._v("我的收藏")]):_vm._e(),(_vm.token)?_c('router-link',{staticClass:"m-hidden-menu",attrs:{"to":"/collection"}},[_c('img',{attrs:{"src":require("@/assets/user.png")}})]):_vm._e()],1)]),_c('div',{staticClass:"mobile",class:{ 'home-mobile-menu': _vm.$route.name === 'Home' }},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/m_logo.png"),"alt":"棠溪"}})]),_c('h2',[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('span',{staticClass:"open-menu",on:{"click":_vm.toggleMenu}},[_c('img',{attrs:{"src":_vm.mobileMenu
              ? require('@/assets/close.png')
              : require('@/assets/menu.png')}})])],1)]),_c('router-view',{key:_vm.key}),_c('div',{staticClass:"footer",class:{ 'home-pc-footer': _vm.$route.name === 'Home' }},[_c('div',{staticClass:"top"},[_c('div',[_c('div',{staticClass:"info"},[_c('p',[_vm._v(_vm._s(_vm.info.site_name))]),_c('div',[_c('p',[_vm._v("地址："+_vm._s(_vm.info.address))]),_c('p',[_vm._v("电话："+_vm._s(_vm.info.telephone))]),_c('p',[_vm._v("邮箱："+_vm._s(_vm.info.mailbox))])])]),_c('div',{staticClass:"link"},[_c('div',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("网站首页")]),_c('router-link',{attrs:{"to":"/product"}},[_vm._v("产品中心")]),_c('router-link',{attrs:{"to":"/about/qyjj"}},[_vm._v("棠溪简介")]),_c('router-link',{attrs:{"to":"/media"}},[_vm._v("媒体聚焦")]),_c('router-link',{staticClass:"jwh",attrs:{"to":"/swordCulture"}},[_vm._v("剑 文 化")]),_c('router-link',{attrs:{"to":"/contact"}},[_vm._v("联系我们")]),_c('router-link',{attrs:{"to":"/order"}},[_vm._v("订购流程")])],1)]),_c('div',{staticClass:"contact"},[_vm._m(0),_c('div',[_c('p',[_vm._v("支付方式 配送方式 售后服务")]),_c('div',[_c('p',[_vm._v("联系我们 "+_vm._s(_vm.info.telephone))]),_c('p',[_vm._v("欢迎关注棠溪官方订阅")])])])])])]),_c('div',{staticClass:"bottom",staticStyle:{"padding-top":"20px"}},[_c('div',[_c('span',[_vm._v("Copyright©2017 "+_vm._s(_vm.info.site_name)+" 版权所有")]),_vm._m(1),_vm._m(2)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ewm"},[_c('img',{staticClass:"pc",attrs:{"src":require("@/assets/qrcode.png")}}),_c('img',{staticClass:"m",attrs:{"src":require("@/assets/qrcode.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticStyle:{"color":"#b4b4b4"},attrs:{"href":"https://beian.miit.gov.cn","target":"view_window"}},[_c('span',[_vm._v("豫ICP备2022009980号-1")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticStyle:{"margin-left":"20px","display":"inline-block","text-decoration":"none","height":"20px","line-height":"20px"},attrs:{"target":"_blank","href":"https://www.beian.gov.cn"}},[_c('img',{staticStyle:{"float":"left"},attrs:{"src":require("@/assets/gongan.png")}}),_c('p',{staticStyle:{"float":"left","height":"20px","line-height":"20px","margin":"0px 0px 0px 5px","color":"#939393"}},[_vm._v("豫公网安备 41172102000134号")])])}]

export { render, staticRenderFns }