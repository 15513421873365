<template>
  <div>
    <div class="header">
      <div
        class="menu"
        :class="{
          'show-menu': mobileMenu,
          'home-pc-menu': $route.name === 'Home',
        }"
      >
        <div>
          <router-link
            class="hidden-menu"
            to="/"
            :class="{ 'router-active': ['Home'].includes($route.name) }"
            >首页</router-link
          >
          <router-link
            to="/product"
            :class="{
              'router-active': ['Product', 'ProductDetail'].includes(
                $route.name
              ),
            }"
            >产品中心</router-link
          >
          <router-link
            to="/swordCulture"
            :class="{
              'router-active': ['SwordCulture', 'SwordCultureDetail'].includes(
                $route.name
              ),
            }"
            >剑文化</router-link
          >
          <router-link
            :to="{ name: 'About', params: { type: 'qyjj' } }"
            :class="{ 'router-active': $route.name === 'About' }"
            >关于棠溪</router-link
          >
          <span class="logo">
            <router-link to="/">
              <img src="@/assets/logo.png" alt="棠溪" />
            </router-link>
          </span>
          <router-link
            to="/media"
            :class="{
              'router-active': [
                'MediaDetail',
                'Media',
                'MediaWccp',
                'MediaSpzx',
                'SpzxDetail',
                'WccpDetail',
              ].includes($route.name),
            }"
            >媒体聚焦</router-link
          >
          <router-link
            to="/order"
            :class="{ 'router-active': ['Order'].includes($route.name) }"
            >订购流程</router-link
          >
          <router-link
            to="/wealth"
            :class="{ 'router-active': ['Wealth'].includes($route.name) }"
            >财富机会</router-link
          >
          <router-link
            :class="{
              'router-active': $route.name === 'Contact',
              'hidden-menu': $route.name === 'Home',
            }"
            to="/contact"
            >联系我们</router-link
          >
          <router-link
            v-if="token"
            class="hidden-menu"
            :class="{ 'router-active': $route.name === 'Collection' }"
            to="/collection"
            >我的收藏</router-link
          >
          <router-link v-if="token" class="m-hidden-menu" to="/collection">
            <img src="@/assets/user.png" />
          </router-link>
        </div>
      </div>
      <div
        class="mobile"
        :class="{ 'home-mobile-menu': $route.name === 'Home' }"
      >
        <router-link class="logo" to="/">
          <img src="@/assets/m_logo.png" alt="棠溪" />
        </router-link>
        <h2>{{ $route.meta.title }}</h2>
        <span class="open-menu" @click="toggleMenu">
          <img
            :src="
              mobileMenu
                ? require('@/assets/close.png')
                : require('@/assets/menu.png')
            "
          />
        </span>
      </div>
    </div>
    <router-view :key="key"></router-view>
    <div class="footer" :class="{ 'home-pc-footer': $route.name === 'Home' }">
      <div class="top">
        <div>
          <div class="info">
            <p>{{ info.site_name }}</p>
            <div>
              <p>地址：{{ info.address }}</p>
              <p>电话：{{ info.telephone }}</p>
              <p>邮箱：{{ info.mailbox }}</p>
            </div>
          </div>
          <div class="link">
            <div>
              <router-link to="/">网站首页</router-link>
              <router-link to="/product">产品中心</router-link>
              <router-link to="/about/qyjj">棠溪简介</router-link>
              <router-link to="/media">媒体聚焦</router-link>
              <router-link class="jwh" to="/swordCulture">剑 文 化</router-link>
              <router-link to="/contact">联系我们</router-link>
              <router-link to="/order">订购流程</router-link>
            </div>
          </div>
          <div class="contact">
            <div class="ewm">
              <img class="pc" src="@/assets/qrcode.png" />
              <img class="m" src="@/assets/qrcode.png" />
            </div>
            <div>
              <p>支付方式 配送方式 售后服务</p>
              <div>
                <p>联系我们 {{ info.telephone }}</p>
                <p>欢迎关注棠溪官方订阅</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom" style="padding-top: 20px;">
        <div>
          <span>Copyright©2017 {{ info.site_name }} 版权所有</span>
          <a
            href="https://beian.miit.gov.cn"
            target="view_window"
            style="color: #b4b4b4"
          >
            <span>豫ICP备2022009980号-1</span>
          </a>
          <!--<div style="width:300px;margin:0 auto; padding:20px 0;">-->
                <a target="_blank" href="https://www.beian.gov.cn" style="margin-left:20px;display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                    <img src="@/assets/gongan.png" style="float:left;"/>
                    <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">豫公网安备 41172102000134号</p >
                </a>
            <!--</div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAllConfig } from "@/api/index";
export default {
  data() {
    return {
      mobileMenu: false,
      info: {
        site_name: "",
        address: "",
        telephone: "",
        mailbox: "",
      },
    };
  },
  computed: {
    ...mapGetters(["token"]),
    key() {
      return this.$route.fullPath;
    },
  },
  watch: {
    $route() {
      this.mobileMenu = false;
    },
  },

  created() {
    getAllConfig()
      .then((res) => {
        console.log(res);
        let data = res.data.data;
        this.info.site_name = data.filter(
          (item) => item.key === "site_name"
        )[0].value;
        this.info.address = data.filter(
          (item) => item.key === "address"
        )[0].value;
        this.info.telephone = data.filter(
          (item) => item.key === "telephone"
        )[0].value;
        this.info.mailbox = data.filter(
          (item) => item.key === "mailbox"
        )[0].value;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    toggleMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .header {
    background: #3f3f45;
    > .mobile {
      display: none;
    }

    > .menu {
      // width: 1760px;
      background: #3f3f45;
      margin: 0 auto;
      font-size: 0;
      text-align: center;
      white-space: nowrap;
      height: 72px;
      position: relative;
      > div {
        display: flex;
        justify-content: space-around;
        > a {
          display: inline-block;
          font-size: 22px;
          color: #ffffff;
          line-height: 72px;
          // margin: 0 68px;
          position: relative;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        > .router-active {
          color: #d9b078;
        }
        > .router-active::after {
          display: block;
        }
        > a::after {
          display: none;
          content: "";
          width: 48px;
          height: 4px;
          background: #caa36f;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        > a.avtive::after {
          display: block;
        }
        > a:hover {
          color: #d9b078;
        }
        > .hidden-menu {
          display: none;
        }
        > .logo {
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          transform: translateX(-100%);
          img {
            height: 107px;
          }
        }
        > .m-hidden-menu {
          > img {
            margin-top: 12px;
          }
        }
      }
    }
    > .home-pc-menu {
      height: 86px;
      width: 100%;
      > div {
        > a {
          line-height: 86px;
          color: #fff;
          vertical-align: top;
          margin: 0 36px;
        }
        > a:hover {
          font-weight: bold;
          color: #fff;
        }
        > .logo {
          height: 86px;
          overflow: hidden;
          margin: 0 74px;
          position: static;
          transform: none;
        }
        > .m-hidden-menu {
          > img {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .footer {
    .top {
      background: #bd9156;
      > div {
        background: #bd9156;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 30px 0;
        > div {
          display: inline-block;
          vertical-align: middle;
        }
        .info {
          text-align: left;
          > p {
            font-size: 26px;
            color: #ffffff;
            line-height: 32px;
            margin-bottom: 20px;
            font-family: RTWSShangYaG0v1-Regular, RTWSShangYaG0v1;
          }
          > div {
            > p {
              font-size: 16px;
              color: #ffffff;
              line-height: 22px;
              margin-bottom: 4px;
              font-family: PingFangSC-Regular, PingFang SC;
            }
            > p:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }
        .link {
          margin: 0 110px;
          padding: 0 71px;
          border-left: 1px solid rgba(255, 255, 255, 0.3);
          border-right: 1px solid rgba(255, 255, 255, 0.3);
          width: 305px;
          text-align: left;
          a {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
            display: inline-block;
            margin: 0 44px;
            margin-bottom: 8px;
            font-family: STSongti-SC-Regular, STSongti-SC;
          }
          .jwh {
            letter-spacing: 1px;
          }
        }
        .contact {
          text-align: left;
          > div {
            display: inline-block;
            vertical-align: middle;
          }
          .m {
            display: none;
          }
          img {
            height: 96px;
          }
          > div {
            > p {
              font-size: 20px;
              font-weight: 600;
              color: #ffffff;
              line-height: 28px;
              margin-bottom: 13px;
              font-family: PingFangSC-Semibold, PingFang SC;
            }
            > div {
              > p {
                font-size: 16px;
                color: #ffffff;
                line-height: 22px;
                margin-bottom: 4px;
                font-family: PingFangSC-Regular, PingFang SC;
              }
              > p:nth-last-child(1) {
                margin-bottom: 0;
              }
            }
          }
          > div:nth-last-child(1) {
            margin-left: 14px;
          }
        }
      }
    }
    .bottom {
      background: #000000;
      > div {
        background: #000000;
        // width: 1760px;
        margin: 0 auto;
        line-height: 31px;
        text-align: center;
        font-size: 12px;
        color: #b4b4b4;
        > span {
          display: inline-block;
          margin: 0 15px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
    }
  }
  .home-pc-footer {
    .top {
      background: #211e1a;
      > div {
        background: #211e1a;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    > .menu {
      // display: none;
      transition: all 0.3s;
      position: fixed;
      top: 49px;
      left: 0;
      right: 0;
      bottom: 100%;
      background: rgba(0, 0, 0, 0.5);
      overflow: hidden;
      .logo {
        display: none;
      }
      > div {
        padding: 0 30px;
        background: #ffffff;
        > a {
          display: block;
          font-size: 16px;
          color: #333333;
          line-height: 50px;
          opacity: 0.98;
          text-align: center;
          border-bottom: 1px solid #eeeeee;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        > .router-active {
          color: #bb9663;
        }
        > .m-hidden-menu {
          display: none;
        }
      }
    }
    > .home-mobile-menu {
      overflow: hidden;
    }
    > .show-menu {
      // display: block;
      top: 49px;
      bottom: 0;
    }
    > .mobile {
      display: block;
      line-height: 50px;
      text-align: center;
      background: #3f3f45;
      position: relative;
      > .logo {
        position: absolute;
        top: 0;
        left: 15px;
        img {
          height: 63px;
        }
      }
      > h2 {
        font-size: 16px;
        color: #fff;
        font-family: PingFangSC-Medium, PingFang SC;
      }
      > .open-menu {
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translateY(-50%);
        line-height: normal;
        img {
          height: 15px;
        }
      }
    }
  }
  .footer {
    .top {
      background: #bd9156;
      padding: 28px 20px 19px;
      > div {
        .info {
          > p {
            font-size: 23px;
            color: #ffffff;
            line-height: 30px;
            margin-bottom: 17px;
            font-family: RTWSShangYaG0v1-Regular, RTWSShangYaG0v1;
          }
          > div {
            > p {
              font-size: 14px;
              color: #ffffff;
              line-height: 20px;
              margin-bottom: 2px;
              font-family: PingFangSC-Regular, PingFang SC;
            }
            > p:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }
        .link {
          display: none;
        }
        .contact {
          margin-top: 20px;
          text-align: center;
          .pc {
            display: none;
          }
          img {
            height: 75px;
            margin-bottom: 10px;
          }
          > div {
            > p {
              font-size: 18px;
              font-weight: 600;
              color: #ffffff;
              line-height: 25px;
              margin-bottom: 10px;
              font-family: PingFangSC-Semibold, PingFang SC;
            }
            > div {
              > p {
                font-size: 14px;
                color: #ffffff;
                line-height: 20px;
                margin-bottom: 2px;
                font-family: PingFangSC-Regular, PingFang SC;
              }
              > p:nth-last-child(1) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    .bottom {
      background: #000000;
      text-align: center;
      > div {
        padding: 5px 0;
        > span {
          display: block;
          font-size: 11px;
          font-weight: 400;
          color: #b4b4b4;
          line-height: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
    }
  }
  .home-pc-footer {
    .top {
      background: #211e1a;
      > div {
        background: #211e1a;
      }
    }
  }
}
</style>